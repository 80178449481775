import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from "../views/Home";
import StartUpView from "../views/StartUpView";

export const AccountantRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home></Home>}/>
                <Route path="/startup/:id" element={<StartUpView></StartUpView>}/>
                <Route path="/startup/addIncome/:id" element={<StartUpView></StartUpView>}/>
                <Route path="/startup/addOutcome/:id" element={<StartUpView></StartUpView>}/>
                <Route path="/startup/print/:id" element={<StartUpView></StartUpView>}/>
            </Routes>
        </BrowserRouter>
    )
}