import logo from './logo.svg';
import './App.css';
import './resources/css/main.scss';

import {Header} from "./components/template/Header";
import {Footer} from "./components/template/Footer";
import {AccountantRouter} from "./router/AccountantRouter";
import Home from "./views/Home";
import {StartupContext} from "./context/StartupContext";
import {useStartUps} from "./hooks/useStartUps";

function App() {
    const startUps = useStartUps();
    return (
        <div className="App">
            <Header></Header>
            <StartupContext.Provider value={{startUps}}>
                <div className={"container"}>
                    <AccountantRouter></AccountantRouter>
                </div>
            </StartupContext.Provider>
            <Footer></Footer>
        </div>
    );
}

export default App;
